import React from "react";
import Layout from "../layout";
import Headline from "../components/Headline";
import { Call } from "@material-ui/icons";
import { Container } from "react-bootstrap";
import Contactform from "../components/Contactform";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";

export default function Varasto() {
  const imgdata = useStaticQuery(graphql`{
    bg: file(relativePath: {eq: "customerimg2.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    }
  `)
  const bgImageData = imgdata.bg;

  return (
    <>
    <SEO title="Yhteydenotto" description="Ota yhteyttä!"/>
    <Layout>
        <Headline HeadIcon={Call} header="Yhteydenottolomake" backgroundImageData={bgImageData}/>
    <Container className="page_container">
    <Contactform></Contactform>
    </Container>    
   </Layout>
   </>
  );
}
